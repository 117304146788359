<template>
  <div> 
    <header-slot /> 
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: $route.meta.RequestClientStatusPending }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.departmentalSchedules"
      >PENDING</b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.RequestClientStatusRejected }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.departmentalSchedules"
      >REJECTED</b-nav-item> 
      <b-nav-item
        :to="{ name: $route.meta.RequestClientStatusAccepted }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.departmentalSchedules"
      >ACCEPTED</b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
        <router-view :key="$route.name" /> 
    </b-card>

  </div>
</template>

<script>

export default {

};
</script>

<style scoped>
</style>
